import { convertToHtml } from 'mammoth';
import { DOCUMENT_STYLES, MSO_DEFINITIONS } from '../constants';
import { pdf2html } from '../services/thirdPartyApiCalls';
import { ParsingOptions, read, utils } from 'xlsx';
import { toast } from 'react-toastify';
import {
  formatTableCells,
  getAllNodesInElement,
  insertCodeInBlankCells,
} from './generalizationHelpers';
import { v4 as uuidv4 } from 'uuid';
import { CaptionType, IMapComment, ListTypeEnum } from '../types';
import _ from 'lodash';
import { containsListNumbering, retry } from './utils';

export const convertDocxToHTML = async (docxFile: File) => {
  const arrayBuffer = await docxFile.arrayBuffer();
  const styleMap = `
      p[style-name='Title'] => p.MsoTitle:fresh
      p[style-name='Caption'] => p.font-weight-bold:fresh
      table => table.table-bordered:fresh
      comment-reference => sup
      p.levelone => ol.level-one > li.level-one-item:fresh
      p.leveltwo => ol.level-one > li.level-one-item > ol.level-two > li.level-two-item:fresh
      p.levelthree => ol.level-one > li.level-one-item > ol.level-two > li.level-two-item > ol.level-three > li.level-three-item:fresh
      p.levelfour => ol.level-one > li.level-one-item > ol.level-two > li.level-two-item > ol.level-three > li.level-three-item > ol.level-four > li.level-four-item:fresh
      p.levelfive => ol.level-one > li.level-one-item > ol.level-two > li.level-two-item > ol.level-three > li.level-three-item > ol.level-four > li.level-four-item > ol.level-five > li.level-five-item:fresh
      p.levelsix => ol.level-one > li.level-one-item > ol.level-two > li.level-two-item > ol.level-three > li.level-three-item > ol.level-four > li.level-four-item > ol.level-five > li.level-five-item > ol.level-six > li.level-six-item:fresh
      p.levelseven => ol.level-one > li.level-one-item > ol.level-two > li.level-two-item > ol.level-three > li.level-three-item > ol.level-four > li.level-four-item > ol.level-five > li.level-five-item > ol.level-six > li.level-six-item > ol.level-seven > li.level-seven-item:fresh
    `;
  const htmlDoc = await convertToHtml({ arrayBuffer: arrayBuffer }, { styleMap: styleMap });
  const parser = new DOMParser();
  const targetDoc = parser.parseFromString(htmlDoc.value, 'text/html');
  return { ...htmlDoc, originalName: docxFile.name, value: targetDoc.documentElement.outerHTML };
};

export const convertPdfToHTML = async (pdfFile: File) => {
  const result = await pdf2html(pdfFile);
  return { value: result.data.html, originalName: pdfFile.name };
};

export const convertFileToHTML = async (file: File) => {
  switch (file.type) {
    case 'application/pdf':
      return await convertPdfToHTML(file);
    case 'text/csv':
      return await convertExcelToHTML(file, { type: 'string' });
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return await convertExcelToHTML(file, { type: 'array' });
    default:
      return await convertDocxToHTML(file);
  }
};

const convertExcelToHTML = async (file: File, type: ParsingOptions) => {
  try {
    const result: string[][] = await parseExcel(file, type);
    const html = convertSheetToHTML(result);
    return {
      originalName: file.name,
      value: html,
    };
  } catch (err) {
    console.error(err);
    toast.error('Failed to upload file');
  }
};

const parseExcel = (file: File, type: ParsingOptions): Promise<string[][]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = read(data, type);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData: string[][] = utils.sheet_to_json(worksheet, { header: 1 });

      resolve(jsonData);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    if (type.type === 'string') {
      reader.readAsText(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });
};

const convertSheetToHTML = (data: string[][]) => {
  const worksheet = utils.aoa_to_sheet(data);
  return utils.sheet_to_html(worksheet);
};

const addSuggestionsColors = (
  elements: HTMLElement[],
  updatedAcceptedSuggestions: Array<{ targetNodeId: string; color: string }>,
) => {
  elements.forEach((element) => {
    const suggestion = updatedAcceptedSuggestions.find(
      (suggestion) => suggestion.targetNodeId === element.getAttribute('data-nodeid'),
    );
    const allElements = getAllNodesInElement(element) as HTMLElement[];
    allElements?.forEach((el) => {
      suggestion?.color && (el.style.backgroundColor = suggestion?.color);
    });
  });
};

const removeSuggestionsColors = (elements: HTMLElement[]) =>
  elements.forEach((el) => (el.style.backgroundColor = ''));

export const convertAllFilesToHTML = async (files: File[]) => {
  return Promise.all(files.map((file) => convertFileToHTML(file)));
};

const createNewElementWithAttributes = (
  node: HTMLElement,
  tagName: keyof HTMLElementTagNameMap,
): HTMLElement => {
  const newElement = document.createElement(tagName);
  Array.from(node.attributes).forEach((attr) => {
    newElement.setAttribute(attr.name, attr.value);
  });
  return newElement;
};

const replaceNodeWithNewElement = (
  oldNode: HTMLElement,
  tagName: keyof HTMLElementTagNameMap,
  className?: string,
): void => {
  const newElement = createNewElementWithAttributes(oldNode, tagName);
  if (className) {
    newElement.className = className;
  }
  newElement.innerHTML = oldNode.innerHTML;
  oldNode.parentNode?.replaceChild(newElement, oldNode);
};

const replaceNodes = (
  doc: Document,
  selector: keyof HTMLElementTagNameMap,
  newTagName: keyof HTMLElementTagNameMap,
  className?: string,
): void => {
  const elements = doc.querySelectorAll(selector);
  elements.forEach((oldNode: HTMLElement) => {
    replaceNodeWithNewElement(oldNode, newTagName, className);
  });
};

const fixTitleAndHeadingTags = (doc: Document) => {
  let foundH1BeforeTOC = false;
  let foundTOC = false;

  const loopThroughNodesUntilText = (node: HTMLElement, startText: string) => {
    if (_.toLower(node.textContent || undefined) === _.toLower(startText) || foundTOC) {
      foundTOC = true;
      return;
    }

    if (node.tagName && node.tagName.toLowerCase() === 'h1') {
      foundH1BeforeTOC = true;
      const className = node.querySelector('img') ? '' : 'MsoTitle';
      replaceNodeWithNewElement(node, 'p', className);
    }

    (node.childNodes as NodeListOf<HTMLElement>).forEach((childNode) => {
      if (childNode.nodeType === Node.ELEMENT_NODE) {
        loopThroughNodesUntilText(childNode, startText);
      }
    });
  };

  loopThroughNodesUntilText(doc.body, ListTypeEnum.TABLE_OF_CONTENTS);

  if (foundH1BeforeTOC) {
    replaceNodes(doc, 'h2', 'h1');
    replaceNodes(doc, 'h3', 'h2');
  }
};

export const handleDownload = async (
  innerHtml: string,
  filename: string | undefined,
  updatedAcceptedSuggestions?: any | undefined,
  mappingComments: IMapComment[] = [],
  isAnnotated: boolean | undefined = false,
) => {
  const html = await convertBlobToBase64(innerHtml);
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  let listItems: string[] = [];
  const elements = Array.from(
    doc?.querySelectorAll<HTMLElement>('[title], [data-highlighted], [data-highlight]'),
  );
  const imageTags = doc?.querySelectorAll('img');

  if (elements) {
    removeSuggestionsColors(elements);
    if (isAnnotated) {
      addSuggestionsColors(elements, updatedAcceptedSuggestions);
      if (mappingComments.length) {
        listItems = generateCommentList(mappingComments, elements);
      }
    }
  }

  if (imageTags)
    await Promise.all(
      Array.from(imageTags).map(async (el) => {
        const url: string = (el as HTMLElement).getAttribute('src') || '';
        if (url !== '') {
          const blob = await convertImageToBase64(url);
          (el as HTMLElement).setAttribute('src', blob);
        }
      }),
    );
  const paragraphs = doc.querySelectorAll('p');
  applyCaptionFormatting(paragraphs, 'Table');
  applyCaptionFormatting(paragraphs, 'Figure');

  const idTags = doc?.querySelectorAll('[id]');

  if (idTags)
    Array.from(idTags).forEach((el) => {
      const id: string = (el as HTMLElement).getAttribute('id') || '';
      (el as HTMLElement).setAttribute('name', id);
    });

  Object.values(ListTypeEnum).map((ListType) => insertTableOfContents(doc, ListType));

  fixTitleAndHeadingTags(doc);
  addTOCList(doc);

  const htmlWithInsertedCode = insertCodeInBlankCells(doc.body.innerHTML);
  const formattedTableCells = formatTableCells(htmlWithInsertedCode);
  let content = formattedTableCells
    .replace(/<w:sdt/g, '<w:Sdt')
    .replace(/sdtdocpart=/g, 'SdtDocPart=')
    .replace(/docparttype=/g, 'DocPartType=')
    .replace(/docpartunique=/g, 'DocPartUnique=')
    .replace(/<\/w:sdt>/g, '</w:Sdt>');

  if (isAnnotated && listItems.length) {
    const listHtml = `<div><h3>Comments</h3><ul>${listItems.join('')}</ul></div>`;
    content = `${content}<div class="comments">${listHtml}</div>`;
  }

  exportToDoc(content, filename ?? 'document');
};

const ListConfigurations = {
  [ListTypeEnum.TABLE_OF_CONTENTS]: {
    docPartType: 'Table of Contents',
    fieldCode: `TOC \\o "1-6" \\h \\z \\u`,
  },
  [ListTypeEnum.LIST_OF_TABLES]: {
    docPartType: 'List of Tables',
    fieldCode: `TOC \\h \\z \\c "Table"`,
  },
  [ListTypeEnum.LIST_OF_FIGURES]: {
    docPartType: 'List of Figures',
    fieldCode: `TOC \\h \\z \\c "Figure"`,
  },
};

const getTOCElement = (pTagsWithLinks: HTMLElement[], tableHeader: ListTypeEnum): string => {
  let tableContent = '';
  for (const pTag of pTagsWithLinks) {
    tableContent += `<p>${pTag.outerHTML}</p>`;
  }

  return `<w:Sdt SdtDocPart="t" DocPartType="${
    ListConfigurations[tableHeader].docPartType
  }" DocPartUnique="t" ID=${uuidv4()}>
            <p class="MsoTitle" style="font-weight: bold; text-indent: -1in; margin-left: 1in; margin-top: 12pt; margin-bottom: 6pt;  font-size: 14pt; text-align: center;">${tableHeader}</p>
            <!--[if mso]>
                <p style="text-indent: -1in; margin-left: 1in; margin-top: 12pt; margin-bottom: 6pt;">
                  <span style="mso-element:field-begin;"></span> ${
                    ListConfigurations[tableHeader].fieldCode
                  } <span style="mso-element:field-separator;"></span>
                  <span style="mso-element:field-result;"></span><span style="mso-element:field-end;"> ${tableContent} </span><span style="mso-tab-count:1;"></span><span style="mso-element:footnote-separator;"></span>
                </p>
            <![endif]-->
          </w:Sdt>`;
};

const getParagraphsWithTOCLinks = (
  listSectionNodes: NodeListOf<HTMLElement>,
  startText: ListTypeEnum,
) => {
  const pTagsWithLinks = [];
  let startingNode: HTMLElement | undefined;

  for (const node of listSectionNodes) {
    if (_.toLower(node.textContent || undefined) === _.toLower(startText)) {
      startingNode = node;
      continue;
    } else if (!startingNode) {
      continue;
    }

    if (node.nodeName === 'P') {
      const anchorTags = node.querySelectorAll<HTMLAnchorElement>('a[href^="#_Toc"]');
      if (anchorTags.length === 1) {
        pTagsWithLinks.push(node);
      } else {
        break;
      }
    } else {
      break;
    }
  }
  return { pTagsWithLinks, startingNode };
};

const insertTableOfContents = (
  doc: Document,
  startText: ListTypeEnum = ListTypeEnum.TABLE_OF_CONTENTS,
) => {
  const powerPasteSectionSelector = '.WordSection2';
  const listSection = doc.querySelector<HTMLElement>(powerPasteSectionSelector) || doc.body;
  const childNodes = listSection.childNodes as NodeListOf<HTMLElement>;

  const { pTagsWithLinks, startingNode } = getParagraphsWithTOCLinks(childNodes, startText) || [];
  if (!pTagsWithLinks.length || !startingNode) return;

  const firstPIndex = Array.from(childNodes).findIndex((node) => node === pTagsWithLinks[0]);

  if (firstPIndex === -1) return;

  for (const child of pTagsWithLinks) {
    listSection.removeChild(child);
  }

  const tocElement = getTOCElement(pTagsWithLinks, startText);

  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = tocElement;

  const refNode = childNodes[firstPIndex];
  const children = Array.from(tempDiv.childNodes);
  for (const child of children) {
    listSection.insertBefore(child, refNode);
  }

  listSection.removeChild(startingNode);
};

const getCaptionElement = (
  tableNumber: number,
  tableCaption: string,
  captionType: CaptionType = 'Table',
  delimiter: string,
): string => {
  return `<!--[if mso]>
            <p class="MsoCaption" style="font-weight: bold; text-indent: -1in; margin-left: 1in; margin-top: 12pt; margin-bottom: 6pt;">
                <span style="mso-element:caption; display:inline;">${captionType}</span>
                <span style="mso-element:field-begin;"></span> SEQ ${captionType} \\* ARABIC <span style="mso-element:field-separator;"></span>
                <span style="mso-element:field-result;"></span><span style="mso-element:field-end;">${tableNumber}</span>${
                  delimiter.trim() || ':'
                }&nbsp;&nbsp;${tableCaption}<span style="mso-element:footnote-separator;"></span><span style="mso-tab-count:1;"> </span>
            </p>
          <![endif]-->`;
};

const applyCaptionFormatting = (
  paragraphs: NodeListOf<HTMLParagraphElement>,
  captionType: CaptionType = 'Table',
) => {
  let currentCaptionNumber = 1;
  // Regex to match and extract caption: (Caption followed by digits) + (Non-alphanumeric delimiter) + (Remaining text)
  const captionRegex = new RegExp(`^(${captionType}\\s*\\d+)\\s*([^a-zA-Z0-9])\\s*(.*)`, 'i');
  paragraphs.forEach((paragraph: HTMLParagraphElement) => {
    const childWithToc = paragraph.querySelector('[id^="_Toc"], [name^="_Toc"]');

    if (childWithToc && paragraph.textContent?.match(captionRegex)) {
      const match = paragraph.textContent.match(captionRegex);
      if (match) {
        const delimiter = match[2].trim();
        const captionText = match[3].trim();
        const anchorTags = paragraph.querySelectorAll('a');
        paragraph.innerHTML = getCaptionElement(
          currentCaptionNumber,
          captionText,
          captionType,
          delimiter,
        );
        anchorTags.forEach((anchorTag) => {
          const clonedAnchorTag = anchorTag.cloneNode(true);
          paragraph.appendChild(clonedAnchorTag);
        });
        currentCaptionNumber++;
      }
    }
  });
};

const removeHeadingsNumbering = (nodesToRemove: HTMLElement[]) => {
  const allTextContent = nodesToRemove.reduce(
    (acc, node) => acc + (node.textContent || '').trim(),
    '',
  );

  if (containsListNumbering(allTextContent)) {
    nodesToRemove.forEach((textNode) => textNode.remove());
  }
};

export const addTOCList = (doc: Document) => {
  const headings = [1, 2, 3, 4, 5, 6];
  headings.forEach((heading) => {
    const Elements = doc.querySelectorAll<HTMLElement>(`h${heading}`);
    Elements.forEach((element) => {
      element.setAttribute('style', `mso-list:l15 level${heading} lfo1`);
      const textNodesToDelete: HTMLElement[] = [];
      let foundSpan = false;
      (Array.from(element.childNodes) as HTMLElement[]).forEach((childNode) => {
        if (foundSpan) {
          return;
        }
        if (childNode.nodeType === Node.TEXT_NODE) {
          textNodesToDelete.push(childNode as HTMLElement);
        }
        if (childNode.tagName === 'SPAN') {
          foundSpan = true;
          removeHeadingsNumbering([...textNodesToDelete, childNode]);
        }
      });
    });
  });
};

export const exportToDoc = (element: string, filename: string) => {
  const preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><style type='text/css'>${
    DOCUMENT_STYLES + MSO_DEFINITIONS
  }</style><title>Export HTML To Doc</title></head><body>`;
  const postHtml = '</body></html>';

  const html = preHtml + element + postHtml;

  const blob = new Blob(['\ufeff', html], {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  });

  const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
  filename = filename + '.doc';
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);

  if ((navigator as any).msSaveOrOpenBlob) {
    (navigator as any).msSaveOrOpenBlob(blob, filename);
  } else {
    downloadLink.href = url;

    downloadLink.download = filename;

    downloadLink.click();
  }
  document.body.removeChild(downloadLink);
};

export const convertBlobToBase64 = async (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const images = doc.querySelectorAll('img[src^="blob:"]');

  const convertImage = (img: any) => {
    return new Promise<void>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', img.src, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        const reader = new FileReader() as any;
        reader.onloadend = function () {
          const base64data = btoa(reader.result);
          img.src = 'data:image/jpeg;base64,' + base64data;
          resolve();
        };
        reader.readAsBinaryString(xhr.response);
      };
      xhr.onerror = function () {
        console.error(`Error loading blob ${img.src}`);
        reject();
      };
      xhr.send();
    });
  };

  try {
    await Promise.all(Array.from(images)?.map(convertImage));
    return new XMLSerializer().serializeToString(doc);
  } catch (error) {
    console.error(error);
    return html;
  }
};

export const convertImageToBase64 = async (url: string) => {
  if (url === '') return '';

  const blob = await retry<Blob>(async () => {
    const res = await fetch(url, { cache: 'no-cache' });
    return res.blob();
  });

  if (!blob) return '';
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(blob);
  });
};

const generateCommentList = (mappingComments: IMapComment[], elements: HTMLElement[]) => {
  const listItems: string[] = [];

  mappingComments.forEach((comment) => {
    const element = elements.find((el) => el.getAttribute('data-nodeid') === comment.targetNodeId);
    if (element) {
      const sup = document.createElement('sup');
      const anchor = document.createElement('a');
      anchor.href = `#_${comment.targetNodeId}`;
      anchor.innerHTML = `${comment.commentNumber}`;
      sup.appendChild(anchor);
      element.appendChild(sup);
    }
    const text = comment.comment;
    const id = comment.targetNodeId;
    listItems.push(
      `<li>${comment.firstName} ${comment.lastName} - ${text}  <sup>[${comment.commentNumber}]<a id='_${id}' name='_${id}'></a></sup></li>`,
    );
  });

  return listItems;
};

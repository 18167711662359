import axios from '../../../helpers/AxiosConfig';
import {
  CreateCatalogItemParams,
  CreateCatalogItemResponse,
  DeleteCatalogsItemResponse,
  GetCatalogItemFilterOptionsResponse,
  GetCatalogItemsParams,
  GetCatalogsItemsResponse,
  GetMaterialssByProjectIdsResponse,
  GetProcessidsCatalogItemsResponse,
  UpdateCatalogItemParams,
} from './catalogItems.types';

export const getCatalogItems = async (params: GetCatalogItemsParams) => {
  return axios.get<GetCatalogsItemsResponse>('catalogItems', {
    params,
  });
};

export const deleteCatalogItem = async (id: number) => {
  return axios.delete<DeleteCatalogsItemResponse>(`catalogItems/${id}`);
};

export const createCatalogItem = async (data: CreateCatalogItemParams) => {
  return axios.post<CreateCatalogItemResponse>(`catalogItems/create`, data);
};

export const updateCatalogItem = async (data: UpdateCatalogItemParams) =>
  axios.put<CreateCatalogItemResponse>(`/catalogItems/${data.id}`, data);

export const getCatalogItemFilterOptions = async () =>
  axios.get<GetCatalogItemFilterOptionsResponse>(`/catalogItems/filter-options`);

export const getProcessidsCatalogItems = async (ids: number[]) => {
  return axios.get<GetProcessidsCatalogItemsResponse>('/catalogItems/getProcessidsByMaterialIds', {
    params: { materialIds: JSON.stringify(ids) },
  });
};

export const getMaterialsByProjectIds = async (projectIds: number[]) => {
  return axios.get<GetMaterialssByProjectIdsResponse>('/catalogItems/getMaterialsByProjectIds', {
    params: { projectIds: JSON.stringify(projectIds) },
  });
};

import { useContext, useState } from 'react';
import Modal from 'react-modal';
import Button from '../elements/Button';
import { addComment } from '../../services/apiCalls';
import ModalInputFields from './modalInputFields';
import ModalHeader from './ModalHeader';
import { MappingContext } from '../../contexts/MappingContext';
import { UserInfoContext } from '../../pages/Container';
import { notifySuccess } from '../../helpers/utils';
import { GeneralizationContext } from '../../contexts/GeneralizationContext';

const AddCommentModal = ({
  commentModalIsOpen,
  closeModal,
  suggestionId,
  comments = [],
  updateStatus = false,
}) => {
  const {
    mappingComments,
    setMappingComments,
    currentMapping,
    commentNumber,
    setCommentNumber,
    setIsCommentDisplayed,
  } = useContext(MappingContext);
  const { sessionId } = useContext(GeneralizationContext);
  const { firstName, lastName, userId } = useContext(UserInfoContext);
  const intialValues = {
    comment: '',
    firstName: firstName,
    lastName: lastName,
    commentNumber: 0,
  };
  const [commentData, setCommentData] = useState(intialValues);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const inputList = [
    {
      label: 'Comment',
      type: 'text',
      name: 'fileName',
      value: commentData.comment,
      placeholder: 'Add Comment',
    },
  ];

  const addCommentModalCustomStyles = {
    content: {
      top: '50%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '35%',
      minHeight: 'fit-content',
    },
    overlay: {
      background: 'rgba(71,84,93,0.8)',
      zIndex: 1,
    },
  };

  const submitData = async (e) => {
    e.preventDefault();
    if (commentData.comment === '') {
      setError('Required Fields missing');
      return;
    }

    setCommentData(intialValues);
    setIsLoading(true);
    closeModalHandler();
    const data = {
      comment: commentData.comment,
      commentNumber: commentNumber,
      userId: userId,
    };
    if (suggestionId) {
      data.suggestionId = suggestionId;
    } else {
      data.mappingId = currentMapping.mappingId;
      data.commentNumber = commentNumber;
    }
    updateStatus && (data.sessionId = sessionId);
    addComment(data).then((res) => {
      setCommentNumber(res.data.commentNumber);
      setIsCommentDisplayed(true);
      setMappingComments([
        ...mappingComments,
        {
          ...commentData,
          commentNumber: res.data.commentNumber,
          targetNodeId: res.data.suggestion?.targetNodeId,
        },
      ]);
      notifySuccess('Comment Added');
    });
  };

  const closeModalHandler = () => {
    setCommentData(intialValues);
    setError('');
    closeModal();
    setIsLoading(false);
  };

  const onChangeHandler = (e) => {
    setCommentData({
      comment: e.target.value,
      firstName: firstName,
      lastName: lastName,
      commentNumber: commentNumber,
      userId: userId,
      mappingNumber: currentMapping?.mappingNumber,
    });
  };

  return (
    <Modal
      isOpen={commentModalIsOpen}
      onRequestClose={closeModalHandler}
      style={addCommentModalCustomStyles}
      ariaHideApp={false}>
      <div className="space-y-4 sm:p-8" data-testid={'comment-modal'}>
        <ModalHeader
          title={'Comment'}
          closeModal={closeModalHandler}
          data-testid={'comment-modal-close'}
        />
        {comments.length > 0 && <div className="h-80 overflow-auto">{comments}</div>}
        <form className="space-y-4" onSubmit={submitData}>
          <ModalInputFields inputList={inputList} onChangeHandler={onChangeHandler} />
          <div className="text-center">
            <Button
              data-testid={'comment-modal-button'}
              type="submit"
              onClick={submitData}
              title="Add Comment"
              className={`text-white
              ${
                isLoading
                  ? ' bg-gray-500 hover:bg-gray-500 pointer-events-none bg-[#C9C9C9]” : “bg-[#438FFE] '
                  : ' bg-primary_bg_color '
              }
              font-medium rounded-lg text-sm px-5 py-2.5 text-center hover:bg-secondary_bg_color`}
            />
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
        </form>
      </div>
    </Modal>
  );
};

export default AddCommentModal;

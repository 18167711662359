import { useState, useEffect, useMemo } from 'react';
import ModalInputFields from '../modals/modalInputFields';
import { Button, Modal } from '@gloabal-regulatory-writing-consulting/gxt-components';

import SvgIcon from '../elements/SvgIcon';
import { Placeholder } from '../../services/api/types';
import _ from 'lodash';
import { usePlaceholder } from '../../services/api/placeholder/usePlaceholderAPI';

export type PlaceholderModalType = {
  modalIsOpen: boolean;
  closeModal: () => void;
  selectedProject?: string;
  existingPlaceholders?: Placeholder[];
  setExistingPlaceholders?: (placeholder: Placeholder[]) => void;
};

const PlaceholderModal = ({
  modalIsOpen,
  closeModal,
  selectedProject,
  existingPlaceholders,
  setExistingPlaceholders,
}: PlaceholderModalType) => {
  const [placeholders, setPlaceholders] = useState<Placeholder[]>([{ newValue: '', oldValue: '' }]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isReplaceDisabled, setIsReplaceDisabled] = useState(true);
  const { addPlaceholders, updatePlaceholder } = usePlaceholder();

  const createModal = !(existingPlaceholders && existingPlaceholders.length);

  const handleAddPlaceholder = () => {
    setPlaceholders([...placeholders, { oldValue: '', newValue: '' }]);
  };

  const inputFields = placeholders.map((placeholder, index) => ({
    fields: [
      {
        type: 'text',
        name: `oldValue-${index}`,
        value: placeholder.oldValue,
        placeholder: 'Add a Placeholder Value',
        inputClassName: 'w-full p-2 text-base font-normal border border-gray-300 rounded bg-white',
        label: 'Define Placeholder',
        labelClassName: 'text-base font-bold text-primary-300',
      },
      {
        type: 'text',
        name: `newValue-${index}`,
        value: placeholder.newValue,
        placeholder: 'Add a Placeholder Value',
        inputClassName: 'w-full p-2 text-base font-normal border border-gray-300 rounded bg-white',
        label: 'Assign Value',
        labelClassName: 'text-base font-bold text-primary-300',
      },
    ],
  }));

  const handleChange = (index: number, field: keyof Placeholder, value: string) => {
    setPlaceholders((prev: Placeholder[]) => {
      const newPlaceholders: Placeholder[] = [...prev];
      newPlaceholders[index][field] = value;
      return newPlaceholders;
    });
  };

  useEffect(() => {
    if (!createModal) {
      const deepCopiedPlaceholders = _.cloneDeep(existingPlaceholders) as Placeholder[];
      setPlaceholders(deepCopiedPlaceholders);
    }
  }, [existingPlaceholders]);

  const handleInputChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const field = e.target.name.split('-')[0] as keyof Placeholder;
    handleChange(index, field, e.target.value);
  };

  const checkFieldsValidity = () => {
    const allFieldsFilled = placeholders.every(
      (p) => p.oldValue.trim() !== '' && p.newValue.trim() !== '',
    );
    setIsReplaceDisabled(!allFieldsFilled);
  };

  const checkUniqueness = () => {
    const oldValues = placeholders.map((p) => p.oldValue);
    const hasDuplicates = oldValues.length !== new Set(oldValues).size;
    if (hasDuplicates) {
      setErrorMessage('All "Define Placeholder" values must be unique.');
    } else {
      setErrorMessage('');
    }
    return !hasDuplicates;
  };

  const existingPlaceholderHash = useMemo(
    () =>
      (existingPlaceholders || []).reduce(
        (acc, placeholder) => {
          acc[placeholder.id?.toString() || ''] = {
            newValue: placeholder.newValue,
            oldValue: placeholder.oldValue,
          };
          return acc;
        },
        {} as Record<string, Omit<Placeholder, 'id'>>,
      ),
    [existingPlaceholders],
  );

  const updatedPlaceholders = useMemo(
    () =>
      placeholders.filter(
        ({ id, oldValue, newValue }) =>
          existingPlaceholderHash[id?.toString() || '']?.oldValue !== oldValue ||
          existingPlaceholderHash[id?.toString() || '']?.newValue !== newValue,
      ),
    [placeholders, existingPlaceholderHash],
  );

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!checkUniqueness()) return;

    try {
      const data = {
        projectId: Number(selectedProject),
        placeholdersValues: createModal ? placeholders : updatedPlaceholders,
      };

      const apiCall = createModal ? addPlaceholders : updatePlaceholder;

      await apiCall.mutateAsync(data);
      handleCloseModal();
    } catch (error: any) {
      console.log(error.data.message);
    }
  };

  const handleCloseModal = () => {
    setPlaceholders([{ oldValue: '', newValue: '' }]);
    setExistingPlaceholders && setExistingPlaceholders([]);
    closeModal();
  };

  useEffect(() => {
    checkFieldsValidity();
  }, [placeholders]);

  return (
    <Modal
      isOpen={modalIsOpen}
      maxWidth={'904px'}
      maxHeight={'800px'}
      style={{
        width: '904px',
        height: '750px',
      }}
      overlay={true}
      onClose={handleCloseModal}>
      <Modal.ModalHeader style={{ margin: '0px auto' }}>
        <Modal.ModalHeaderHeading>
          {createModal ? 'Assign Placeholders' : 'Edit Placeholders'}
        </Modal.ModalHeaderHeading>

        <Modal.ModalHeaderBody>
          <form
            className="w-[824px] h-[556px] flex flex-col gap-6 overflow-y-auto"
            onSubmit={(e) => handleSubmit(e)}>
            <div className="space-y-4">
              {inputFields.map((fieldGroup, index) => (
                <div key={index} className="flex space-x-4 gap-6">
                  <ModalInputFields
                    inputList={fieldGroup.fields}
                    onChangeHandler={(e) => handleInputChange(index, e)}
                    className="h-[70px] w-[400px]"
                  />
                </div>
              ))}
            </div>
          </form>

          {errorMessage && <div className="text-red-500 text-sm">{errorMessage}</div>}
        </Modal.ModalHeaderBody>
      </Modal.ModalHeader>
      <Modal.ModalFooter>
        {createModal && (
          <Button
            variant="secondary"
            onClick={handleAddPlaceholder}
            className="w-[190px] h-[40px] py-2 px-4 gap-3 border-t border-[1px]">
            <SvgIcon iconType="add" /> <span>Add Placeholder</span>
          </Button>
        )}

        <div className="flex gap-3 h-[40px] w-[189px] ml-auto">
          <Button variant="primary" onClick={handleSubmit} disabled={isReplaceDisabled}>
            Replace
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </Modal.ModalFooter>
    </Modal>
  );
};

export default PlaceholderModal;

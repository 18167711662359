import React, { FC, useMemo, useRef, useState } from 'react';
import { Button, SlideOver } from '@gloabal-regulatory-writing-consulting/gxt-components';
import EditCatalogTemplateForm from './EditCatalogTemplateForm';
import { CatalogTemplateFormProps, EditCatalogTemplateProps } from './EditCatalogTemplate.types';
import {
  getMaterialsByProjectIds,
  useCatalog,
  useMappingSessionAPI,
} from '../../../../services/api';
import { DocumentTabs } from '../../../../types';
import useModal from '../../../../hooks/useModal';
import { CheckboxSelectionSlideOver } from '../../../../components/CheckboxSelectionSlideOver';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getProjects } from '../../../../services/api/projectSettings';
import { InProgressEditValues, TargetEditValues } from '../CatalogTable/CatalogTable.types';
import { getAllCatalogItemsWithPagination } from '../../../settings/components/utils/apiHelpers';
import { ItemType } from '../../../../services/api/types';
import { perPageFilterOptions } from '../../../../constants/catalog';
import { handleInfiniteScroll } from '../../../../helpers/utils';

const EditCatalogTemplates: FC<EditCatalogTemplateProps> = ({
  isOpen,
  onClose,
  activeTab,
  defaultValues,
}) => {
  const formRef = useRef<{ submitForm: () => void }>(null);
  const [formData, setFormData] = useState<CatalogTemplateFormProps>({
    ...defaultValues,
    materials: defaultValues.materials || [],
    ctdSections: defaultValues.ctdSections || [],
    project: defaultValues.project || [],
  });

  const { editMappingSession } = useMappingSessionAPI();
  const { editCatalog } = useCatalog();

  const projectSlideOver = useModal();
  const materialsSlideOver = useModal();
  const ctdSectionsSlideOver = useModal();

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const handleSubmitData = async (_data: TargetEditValues) => {
    const data = {
      materials: formData?.materials?.map((material) => material.value) || [],
      ctdSections: formData?.ctdSections?.map((ctdSection) => ctdSection.value) || [],
    };

    if (defaultValues.id) {
      await editCatalog.mutateAsync({ id: +defaultValues.id, data });
    }
    onClose();
  };

  const handleAutomatedSubmitData = async (data: TargetEditValues) => {
    const sessionData = {
      id: defaultValues?.id,
      title: data.templateName || '',
      version: data.version ?? 1,
      materials: formData?.materials?.map((region) => region.value) || [],
      ctdSections: formData?.ctdSections?.map((ctdSection) => ctdSection.value) || [],
    };

    await editMappingSession.mutateAsync(sessionData);
    onClose();
  };

  const handleInProgressSubmitData = async (data: InProgressEditValues) => {
    const sessionData = {
      id: defaultValues.id,
      title: data.title || '',
      projectId: formData?.project?.[0]?.value,
      ctdSections: formData?.ctdSections?.map((ctdSection) => ctdSection.value) || [],
    };

    await editMappingSession.mutateAsync(sessionData);
    onClose();
  };

  const editTabProperties: Record<
    DocumentTabs,
    { heading: string; submitData: (data: any) => void }
  > = {
    [DocumentTabs.AUTOMATED]: {
      heading: 'Edit Automated Template Properties',
      submitData: handleAutomatedSubmitData,
    },
    [DocumentTabs.TARGET]: {
      heading: 'Edit Target Template Properties',
      submitData: handleSubmitData,
    },
    [DocumentTabs.SOURCE]: {
      heading: 'Edit Source Properties',
      submitData: handleSubmitData,
    },
    [DocumentTabs.IN_PROGRESS]: {
      heading: 'Edit In-Progress Document Properties',
      submitData: handleInProgressSubmitData,
    },
    [DocumentTabs.PRE_AUTHORED]: {
      heading: 'Edit Pre-Authored Document Properties',
      submitData: (_data: any) => {},
    },
    [DocumentTabs.COMPLETED]: {
      heading: 'Edit Completed Document Properties',
      submitData: (_data: any) => {},
    },
  };

  const displayProjectSlideOver = activeTab === DocumentTabs.IN_PROGRESS;

  const { data: projectResponse } = useQuery({
    queryKey: ['catalog-edit-projects'],
    queryFn: () => getProjects({}),
    enabled: activeTab === DocumentTabs.IN_PROGRESS,
  });

  const { data: materialsResponse } = useQuery({
    queryKey: ['materials', formData.project, activeTab],
    queryFn: () =>
      getMaterialsByProjectIds(formData?.project?.map((project) => +project.value) || []),
    enabled: activeTab === DocumentTabs.IN_PROGRESS ? (formData?.project?.length || 0) > 0 : true,
  });

  const {
    data: ctdSectionsResponse,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [ItemType.CTDSection],
    queryFn: ({ pageParam = 1 }) =>
      getAllCatalogItemsWithPagination(ItemType.CTDSection, {
        page: pageParam,
        perPage: perPageFilterOptions,
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.prevOffset * perPageFilterOptions > lastPage.total) {
        return undefined;
      }
      return lastPage?.prevOffset + 1;
    },
    initialPageParam: 1,
  });

  const projectsData = useMemo(
    () =>
      (projectResponse?.data.data || []).map(({ id, name }) => ({
        value: id.toString(),
        label: name,
      })),
    [projectResponse],
  );

  const materialsData = useMemo(
    () =>
      (materialsResponse?.data.materials || [])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ id, name }) => ({
          value: id.toString(),
          label: name,
        })),
    [materialsResponse],
  );

  const ctdSectionsData = useMemo(
    () =>
      ctdSectionsResponse?.pages?.flatMap(
        (page) =>
          page?.items.map(({ id, name }) => ({
            value: id.toString(),
            label: name,
          })),
      ) || [],
    [ctdSectionsResponse],
  );

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    handleInfiniteScroll(event, hasNextPage, isFetchingNextPage, fetchNextPage);
  };

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} mountElementId="styled-wrapper" width="28rem">
      <SlideOver.Header className="flex p-6 flex-col items-start gap-2 self-stretch bg-primary-50 text-system-50">
        {editTabProperties[activeTab].heading}
      </SlideOver.Header>
      <EditCatalogTemplateForm
        ref={formRef}
        handleSubmitData={editTabProperties[activeTab].submitData}
        activeTab={activeTab}
        defaultValues={formData}
        openProjectSlideOver={projectSlideOver.openModal}
        openMaterialsSlideOver={materialsSlideOver.openModal}
        openCTDSectionsSlideOver={ctdSectionsSlideOver.openModal}
        setFormData={setFormData}
      />
      {displayProjectSlideOver && projectSlideOver.show && (
        <CheckboxSelectionSlideOver
          isOpen={projectSlideOver.show}
          handleClose={projectSlideOver.closeModal}
          onSave={(value) => setFormData({ ...formData, project: value })}
          options={projectsData}
          selectedOptions={formData.project}
          displayBackButton
          title="Projects"
          multiple={false}
        />
      )}
      {materialsSlideOver.show && (
        <CheckboxSelectionSlideOver
          isOpen={materialsSlideOver.show}
          handleClose={materialsSlideOver.closeModal}
          onSave={(value) => setFormData({ ...formData, materials: value })}
          options={materialsData}
          selectedOptions={formData.materials}
          displayBackButton
          title="Materials"
        />
      )}
      {ctdSectionsSlideOver.show && (
        <CheckboxSelectionSlideOver
          isOpen={ctdSectionsSlideOver.show}
          handleClose={ctdSectionsSlideOver.closeModal}
          onSave={(value) => setFormData({ ...formData, ctdSections: value })}
          options={ctdSectionsData}
          selectedOptions={formData.ctdSections}
          displayBackButton
          title="CTD Sections"
          handleScroll={handleScroll}
          fetchingNextPage={isFetchingNextPage}
        />
      )}
      <SlideOver.Footer className="flex flex-col items-center self-stretch">
        <div className="flex gap-4 justify-end items-center flex-grow flex-shrink flex-basis-0 w-full">
          <Button variant="primary" onClick={handleFormSubmit}>
            Save
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      </SlideOver.Footer>
    </SlideOver>
  );
};

export default EditCatalogTemplates;

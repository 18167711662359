import { forwardRef, useImperativeHandle, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Input, TagsField } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { InputError } from '../../../../components/inputError';
import { UserFormProps } from './EditUsers.types';
import { UserSchema } from './utils/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { userInputList } from './utils/InputFieldList';
import { UserFormValues } from './utils/constants';

type EditUserFormProps = {
  handleSubmitData: (data: UserFormProps) => void;
  defaultValues?: UserFormProps;
  userRoles: Array<{ label: string; value: string }>;
  rolesOnClickHandler: () => void;
  updateFormValidity: (isValid: boolean) => void;
};

const EditUserForm = forwardRef<{ submitForm: () => void }, EditUserFormProps>(
  (
    {
      handleSubmitData,
      defaultValues = UserFormValues,
      userRoles,
      rolesOnClickHandler,
      updateFormValidity,
    },
    ref,
  ) => {
    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      reset,
    } = useForm<UserFormProps>({
      resolver: zodResolver(UserSchema),
      mode: 'onChange',
      defaultValues,
    });

    const onSubmit: SubmitHandler<UserFormProps> = (data) => {
      handleSubmitData({ ...data, roles: userRoles.map((role) => role.value) || [] });
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        handleSubmit(onSubmit)();
      },
    }));

    useEffect(() => {
      reset(defaultValues);
    }, [defaultValues, reset]);

    useEffect(() => {
      updateFormValidity(isValid);
    }, [isValid, updateFormValidity]);

    return (
      <form className="flex flex-col gap-4 self-stretch">
        {userInputList.map((input, index) => (
          <Controller
            key={index}
            name={input.name as keyof UserFormProps}
            control={control}
            render={({ field }) => (
              <div className="w-full">
                <Input
                  {...field}
                  label={input.label}
                  type={input.type}
                  disabled={input.disabled}
                  className="w-full"
                  step={0.1}
                  customStyles={{ input: { height: '2.625rem' } }}
                  error={!!errors[input.name as keyof UserFormProps]?.message}
                  helpText={
                    <InputError errors={errors} field={input.name as keyof UserFormProps} />
                  }
                />
              </div>
            )}
          />
        ))}
        <TagsField value={userRoles} label="Roles" onClick={rolesOnClickHandler} />
      </form>
    );
  },
);

EditUserForm.displayName = 'EditUserForm';

export default EditUserForm;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notifyError, notifySuccess } from '../../../helpers/utils';
import { createNewProject, updateProjectData, deleteProject } from './projectSettings';

export const useProject = () => {
  const queryClient = useQueryClient();

  const createProject = useMutation({
    mutationFn: createNewProject,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ['projects'],
      });
      notifySuccess(data.message);
    },
    onError: (err) => {
      console.error(err);
      notifyError(err.message || 'An error occurred');
    },
  });

  const updateProject = useMutation({
    mutationFn: updateProjectData,
    onSuccess: ({ data }) => {
      notifySuccess(data.message);
    },
    onError: (err) => {
      console.error(err);
      notifyError(err.message || 'An error occurred');
    },
  });

  const handleProjectDelete = useMutation({
    mutationFn: deleteProject,
    onSuccess: ({ data }) => {
      notifySuccess(data.message);
    },
    onError: (err) => {
      console.error(err);
      notifyError(err.message || 'An error occurred');
    },
  });

  return {
    createProject,
    updateProject,
    handleProjectDelete,
  };
};

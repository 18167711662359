import _ from 'lodash';
import { useContext, useMemo } from 'react';
import { useExportCsvContext } from '../../../contexts/ExportCsvContext';
import { GeneralizationContext } from '../../../contexts/GeneralizationContext';
import { MappingContext } from '../../../contexts/MappingContext';
import {
  constructSuggestionLocation,
  getMappingNotes,
  downloadHTML,
} from '../../../helpers/dvHelpers';

import { dataKeyEnum, headerEnum, mappingsHeaders, notesHeaders } from '../utils/constants';

import { notifyError } from '../../../helpers/utils';

import {
  fetchMappingSessionSuggestionsAuditTrail,
  fetchSourceFiles,
  getSuggestionRejectionNotes,
} from '../../../services/apiCalls';

import {
  CsvHeaderType,
  GeneralizationDocument,
  GeneralizationSuggestion,
  GeneralizationSuggestionWithColor,
  IDVFormState,
  IRejectionNotes,
} from '../../../types';

interface GeneralizationDVExportProps {
  updatedAcceptedSuggestions: GeneralizationSuggestionWithColor[] | undefined;
  firstName: string;
  lastName: string;
}

export const useDataVerificationCSVExport = ({
  updatedAcceptedSuggestions,
  firstName,
  lastName,
}: GeneralizationDVExportProps) => {
  const { exportCSV } = useExportCsvContext();
  const { editor1Content } = useContext(MappingContext);

  const { sessionId, sourceDocuments, mappingSessionTitle } = useContext(GeneralizationContext);

  const getMappingData = async (finalized = false) => {
    if (!updatedAcceptedSuggestions?.length || !sessionId) {
      notifyError('No mappings available to export');
      return;
    }
    if (editor1Content.html && sourceDocuments) {
      const [auditRes, rejectionNotesRes] = await Promise.all([
        fetchMappingSessionSuggestionsAuditTrail(sessionId),
        getSuggestionRejectionNotes(sessionId),
      ]);
      let suggestions: GeneralizationSuggestion[] = auditRes.data;

      const parser = new DOMParser();
      let previousOrder = 0;
      const catalogIds = suggestions.map((suggestion) => suggestion.sourceFileId);
      const {
        data: sourceDocData,
      }: {
        data: Array<{
          id: number;
          filename: string;
          version: number;
          html: string;
          sourceDocNumber: number;
        }>;
      } = await fetchSourceFiles(Array.from(new Set(catalogIds)));

      if (finalized) {
        suggestions = suggestions.filter((suggestion) => suggestion.selected === true);
      }

      const mappingMeta = suggestions.map((suggestion) => {
        const rejectionNotes = getMappingNotes(
          rejectionNotesRes.data,
          suggestion.id,
        ) as IRejectionNotes;

        const {
          status,
          approvedBy,
          targetNodeId,
          sourceNodeId,
          order,
          targetValue,
          sourceFileId,
          mappedBy,
          positionX,
          positionY,
        } = suggestion;
        if (previousOrder !== order) {
          previousOrder = order as number;
        }
        const targetDoc = parser.parseFromString(editor1Content.html, 'text/html');

        const sourceFile = sourceDocData.find((s) => s.id === sourceFileId);

        const sourceDoc = parser.parseFromString(sourceFile?.html || '', 'text/html');

        const valueDoc = parser.parseFromString(targetValue, 'text/html');
        const targetLocationData = constructSuggestionLocation(targetDoc, targetNodeId);
        const sourceLocationData = constructSuggestionLocation(sourceDoc, sourceNodeId);

        const isTaggedImage = positionX || positionY;

        let tValue = valueDoc.body.textContent || '';
        if (isTaggedImage) {
          tValue += ' - tagged image';
        } else if (targetValue.includes('<img')) {
          tValue = 'image';
        }

        return {
          targetNumber: sessionId,
          templateName: mappingSessionTitle,
          creatorname: mappedBy && `${mappedBy.firstName} ${mappedBy.lastName}`,
          targetValue: tValue,
          originalName: sourceFile?.filename,
          targetLocation: `Table-${targetLocationData.tableNumber} Row-${targetLocationData.tableRowNumber} Col-${targetLocationData.tableColumn}`,
          sourceLocation: `Table-${sourceLocationData.tableNumber} Row-${sourceLocationData.tableRowNumber} Col-${sourceLocationData.tableColumn}`,
          status: status,
          statusUpdaterName: approvedBy && `${approvedBy.firstName} ${approvedBy.lastName}`,
          notes: rejectionNotes.notes,
          suggestedChange: rejectionNotes.suggestedChange,
          sourceDocNumber: sourceFile?.sourceDocNumber,
          sourceDocVersion: sourceFile?.version,
        };
      });
      return mappingMeta;
    }
  };

  const exportMappings = async (finalizedRecords: boolean, fileName: string) => {
    try {
      const mappingMeta = (await getMappingData(finalizedRecords)) || [];
      exportCSV({
        data: mappingMeta,
        filename: `${fileName} - ${`${mappingSessionTitle}(${sessionId}) - DV Records.csv`}`,
        headers: formatHeaders(mappingsHeaders),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getTargetTableNumbersFromSource = (
    targetDoc: Document,
    suggestions: GeneralizationSuggestion[],
    catalogIds: number[],
    sourceDocuments: Map<string, GeneralizationDocument>,
    sourceDocData: Array<{ html: string; id: number }>,
    parser: DOMParser,
  ) => {
    let tableNumbers: number[] = [];
    let sourceTableNumbers: number[] = [];
    const formState: any = [];

    catalogIds.forEach((catalogId) => {
      tableNumbers = [];
      sourceTableNumbers = [];

      suggestions.forEach((rec) => {
        if (rec.sourceFileId === catalogId && rec.selected) {
          const sourceFile = sourceDocData.find((file) => file.id === rec.sourceFileId);

          const sourceDoc = parser.parseFromString(sourceFile?.html || '', 'text/html');

          const targetLocationData = constructSuggestionLocation(targetDoc, rec.targetNodeId);
          const sourceLocationData = constructSuggestionLocation(sourceDoc, rec.sourceNodeId);

          tableNumbers.push(targetLocationData.tableNumber);
          sourceTableNumbers.push(sourceLocationData.tableNumber);
        }
      });
      let sourceDocument: GeneralizationDocument | Record<string, string> = {};
      if (sourceDocuments.get((+catalogId).toString()))
        sourceDocument = sourceDocuments.get((+catalogId).toString()) || {};
      formState.push({
        fileName: sourceDocument.filename,
        tables: [...new Set(tableNumbers)],
        version: sourceDocument.version,
        sourceTables: [...new Set(sourceTableNumbers)],
        sourceId: catalogId,
      });
    });
    return formState;
  };

  const exportDVFormData = async () => {
    if (!updatedAcceptedSuggestions?.length) {
      notifyError('No mappings available to export');
      return;
    }
    if (editor1Content.html && sourceDocuments) {
      try {
        const auditRes = await fetchMappingSessionSuggestionsAuditTrail(sessionId);
        const suggestions: GeneralizationSuggestion[] = auditRes.data;

        const parser = new DOMParser();
        const catalogIds = suggestions.map((suggestion) => suggestion.sourceFileId);
        const { data: sourceDocData }: { data: Array<{ html: string; id: number }> } =
          await fetchSourceFiles(Array.from(new Set(catalogIds)));

        const targetDoc = parser.parseFromString(editor1Content.html, 'text/html');

        const sourceFileAcceptedCount = suggestions.reduce<Record<number, number>>(
          (acc, suggestion) => {
            if (suggestion.approvedBy) {
              if (!acc[suggestion.sourceFileId]) {
                acc[suggestion.sourceFileId] = 0;
              }
              acc[suggestion.sourceFileId]++;
            }
            return acc;
          },
          {},
        );

        const DVFormState = getTargetTableNumbersFromSource(
          targetDoc,
          suggestions,
          Array.from(new Set(catalogIds)),
          sourceDocuments,
          sourceDocData,
          parser,
        );
        const mappingMeta = DVFormState.map((record: IDVFormState) => {
          return {
            originalName: record.fileName || 'N/A',
            targetLocation: record.tables.map((rec: number) => `Table ${rec}\n`).join(''),
            version: record.version ? `v ${(+record.version).toFixed(1)}` : 'N/A',
            sourceLocation: record.sourceTables.map((rec: number) => `Table ${rec}\n`).join(''),
            acceptedSuggestionsCount: sourceFileAcceptedCount[record.sourceId] | 0,
          };
        });
        downloadHTML(mappingMeta, mappingSessionTitle, Number(sessionId), firstName, lastName);
      } catch (error) {
        console.error(error);
        notifyError('Unable to export DV Form');
      }
    }
  };

  const formattedNotesHeaders = useMemo(() => formatHeaders(notesHeaders), [notesHeaders]);

  const exportNotes = () => {
    getSuggestionRejectionNotes(sessionId).then((res) => {
      exportCSV({
        data: _.sortBy(res.data, (note: any) => note.createdAt).reverse(),
        headers: formattedNotesHeaders,
        filename: `${mappingSessionTitle} (session:${sessionId}) - Notes.csv`,
      });
      if (res.data.length === 0) {
        notifyError('No rejection notes are available for export.');
      }
    });
  };

  const exportDetailedRecords = async () => {
    exportMappings(false, 'Detail Records');
  };
  const exportFinalizedRecords = async () => {
    exportMappings(true, 'Finalize Records');
  };
  const exportDVFormRecords = () => {
    exportDVFormData();
  };

  return { exportDetailedRecords, exportFinalizedRecords, exportDVFormRecords, exportNotes };
};

const IDS_TO_OMIT = ['dvStatus'];

const formatHeaders = (headers: CsvHeaderType): CsvHeaderType => {
  return headers
    .filter((header) => {
      return !IDS_TO_OMIT.includes(header.key);
    })
    .map((header: any) => {
      const key = dataKeyEnum[header.key] || header.key;
      return {
        label: headerEnum[key] || header.label,
        key,
      };
    });
};

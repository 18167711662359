/* eslint-disable @typescript-eslint/no-empty-function */

import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../../../components/layout';
import { Header, Button } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { useRef } from 'react';
import CreateProjectForm from '../CreateNewProjectForm/CreateNewProjectForm';
import { ProjectDefaultValues } from '../CreateNewProjectForm/utils';
import {
  CreateProjectFormValues,
  UpdateProjectFormValues,
} from '../CreateNewProjectForm/CreateNewProjectForm.types';
import { useProject } from '../../../../services/api/projectSettings/useProjectAPI';
import { ProjectFormAction } from '../CreateNewProjectForm/CreateNewProjectForm.types';

interface CreateProjectProps {
  action?: ProjectFormAction;
  id?: number;
  projectName?: string;
}

const CreateProject: React.FC<CreateProjectProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { action = 'create', id, projectName } = (location.state as CreateProjectProps) || {};
  const formRef = useRef<{ submitForm: () => void }>(null);
  const { createProject, updateProject } = useProject();

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const createNewProject = async (data: CreateProjectFormValues) => {
    const projectData = {
      name: data.name,
    };
    await createProject.mutateAsync(projectData).then(() => navigate('/project-settings'));
  };

  const projectData = {
    name: projectName,
    id,
  } as UpdateProjectFormValues;

  const updateProjectData = async (data: UpdateProjectFormValues) => {
    if (id && projectName) {
      await updateProject.mutateAsync(data).then(() => navigate('/project-settings'));
    }
  };

  return (
    <Layout>
      <Layout.Header>
        <Header>
          <Header.Heading>
            {action === 'create' ? 'Create new Project' : 'Edit Project'}
          </Header.Heading>
        </Header>
      </Layout.Header>
      <Layout.Body>
        <div>
          <p className="text-primary-200 font-bold text-[1.25rem] leading-[1.875rem] tracking-[0.0015rem]">
            Project Details
          </p>
          <p className="text-neutral-400 font-normal text-[1rem] leading-[1.875rem] tracking-[0.0015rem]">
            All fields are required
          </p>
        </div>
        <CreateProjectForm
          createHandler={createNewProject}
          updateHandler={updateProjectData}
          defaultValues={action === 'create' ? ProjectDefaultValues : projectData}
          ref={formRef}
          updateFormValidity={() => {}}
          action={action}
        />
        <div className="flex gap-6 justify-end w-full">
          <Button variant="primary" onClick={handleSubmit} disabled={false}>
            Save
          </Button>
          <Button variant="secondary" onClick={() => navigate('/project-settings')}>
            Close
          </Button>
        </div>
      </Layout.Body>
    </Layout>
  );
};

export default CreateProject;

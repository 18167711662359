import {
  Filter,
  MessageResponse,
  PaginationFilteredParams,
  PaginationResponse,
  User,
} from '../types';

export const ItemType = {
  Material: 'Material',
  Site: 'Site',
  CTDSection: 'CTDSection',
  ProcessID: 'ProcessID',
  Region: 'Region',
  DPImage: 'DPImage',
} as const;

export type CatalogItems = {
  id?: number;
  name: string;
  type: keyof typeof ItemType;
  createdBy: User;
};
export type Processids = {
  id: number;
  name: string;
};
export type GroupedProcessids = {
  materialId: number;
  processIds: Processids[];
};
export type GetProcessidsCatalogItemsResponse = {
  groupedProcessids: GroupedProcessids[];
};
export type CatalogItemParams = Partial<CatalogItems>;

export type GetCatalogItemsParams = PaginationFilteredParams<CatalogItemParams, CatalogItemParams>;
export type GetCatalogsItemsResponse = PaginationResponse<
  Omit<CatalogItems, 'id'> & {
    id: number;
  }
>;
export type DeleteCatalogsItemResponse = MessageResponse;

export type CreateCatalogItemParams = Omit<CatalogItems, 'id' | 'createdBy' | 'name'> & {
  associatedCatalogItemId?: number;
  [key: string]: string | number | undefined;
};

export type UpdateCatalogItemParams = Partial<Omit<CatalogItems, 'createdBy'>> & {
  associatedCatalogItemId?: number;
};

export type CreateCatalogItemResponse = MessageResponse;

export type GetCatalogItemFilterOptionsResponse = {
  createdBy: Filter;
};

export type CatalogItemFilterParams = {
  createdBy: number;
};

export type GetMaterialssByProjectIdsResponse = {
  materials: (Omit<CatalogItems, 'id'> & {
    id: number;
  })[];
};

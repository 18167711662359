import { StatusType } from '../../../services/api/mappingSession/mappingSession.types';
import {
  CatalogFilterParams,
  CatalogSortParams,
  MappingSessionFilterParams,
  MappingSessionSortParams,
  MappingType,
  PaginationFilteredParams,
} from '../../../services/api/types';
import { DocumentTabs } from '../../../types';
import { Option } from '../../../components/types';

const defaultFilters = (tab: DocumentTabs, templateType: Option['value'] = 'Automated') => {
  if (tab === DocumentTabs.SOURCE || (tab === DocumentTabs.TARGET && templateType === 'Target')) {
    const filters: PaginationFilteredParams<CatalogFilterParams, CatalogSortParams>['filters'] = {
      documentType: tab,
    };
    return filters;
  } else {
    let filters: PaginationFilteredParams<
      MappingSessionFilterParams,
      MappingSessionSortParams
    >['filters'];
    if (tab === DocumentTabs.TARGET && templateType === 'Automated') {
      filters = {
        mappingType: MappingType.AUTO_MAPPING,
        status: StatusType.COMPLETED,
      };
    } else if (tab === DocumentTabs.AUTOMATED) {
      filters = {
        mappingType: MappingType.AUTO_MAPPING,
        status: StatusType.COMPLETED,
      };
    } else if (tab === DocumentTabs.IN_PROGRESS) {
      filters = {
        mappingType: [MappingType.AI_AUTHORING, MappingType.AUTHORING],
        status: [
          StatusType.READY_TO_AUTHOR,
          StatusType.AUTHORING_IN_PROGRESS,
          StatusType.GENERATING_AI_SUGGESTIONS,
          StatusType.SUGGESTIONS_FAILED,
          StatusType.READY_TO_AUTHOR,
          StatusType.AUTHORING_IN_PROGRESS,
          StatusType.READY_TO_REVIEW,
          StatusType.VERIFICATION_IN_PROGRESS,
          StatusType.AUTHOR_REVIEW,
          StatusType.MAPPING_IN_PROGRESS,
        ],
      };
    } else if (tab === DocumentTabs.COMPLETED) {
      filters = {
        mappingType: [MappingType.AI_AUTHORING, MappingType.AUTHORING],
        status: [StatusType.COMPLETED],
      };
    }
    return filters;
  }
};

export default defaultFilters;

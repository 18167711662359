import { SlideOverFilters } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { FilterOptions } from '@gloabal-regulatory-writing-consulting/gxt-components/dist/components/slideOverFilters/SlideOverFilters';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { getProjectFilterOptions } from '../../../services/api/projectSettings';
import { ProjectSettingsFilterSlideOverType } from '../types';

const ProjectSettingsFilterSlideOver = ({
  show,
  closeModal,
  handleApplyFilters,
  filters,
}: ProjectSettingsFilterSlideOverType) => {
  const [appliedFilters, setAppliedFilters] = useState(filters);

  const handleApply = () => {
    appliedFilters && handleApplyFilters(appliedFilters);
    closeModal();
  };

  const { data } = useQuery({
    queryFn: getProjectFilterOptions,
    queryKey: ['project-filter-options'],
  });

  const filterOptions = useMemo(
    () => ({
      createdBy: data?.data?.createdBy || [],
      materialIds: data?.data?.catalogItem || [],
    }),
    [data?.data],
  );

  const getFilterOptions = (key: keyof typeof filterOptions, header: string): FilterOptions => {
    return {
      header,
      isSearchAble: filterOptions[key].length >= 5,
      checkboxOptions: filterOptions[key]
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((item) => ({
          label: item.label,
          name: key,
          value: item.value,
          checked: appliedFilters?.[key]?.includes(item.value.toString()),
          onChange(filters) {
            setAppliedFilters(filters);
          },
        })),
      onChangeCallback: () => {},
      isAccordionOpen: (appliedFilters?.[key] || []).length > 0,
    };
  };

  return (
    <SlideOverFilters
      isOpen={show}
      title="Project Filters"
      onCloseHandler={closeModal}
      onApplyHandler={handleApply}
      onResetHandler={() => {
        setAppliedFilters({});
      }}
      filtersOptions={[
        getFilterOptions('materialIds', 'Materials'),
        getFilterOptions('createdBy', 'Created By'),
      ]}
    />
  );
};

export default ProjectSettingsFilterSlideOver;

import { forwardRef, useImperativeHandle, useEffect, Dispatch, SetStateAction } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Input, TagsField } from '@gloabal-regulatory-writing-consulting/gxt-components';
import InputError from '../../../../components/inputError/InputError';
import { CatalogTemplateFormProps } from './EditCatalogTemplate.types';
import {
  automatedSchema,
  inProgressSchema,
  sourceSchema,
  targetSchema,
} from '../../../CatalogTemplateForm/utils/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { getInputList } from './utils/InputFieldList';
import { CatalogTemplateFormValues } from './utils/constants';
import { DocumentTabs } from '../../../../types';
import { AnyZodObject } from 'zod';

interface EditCatalogTemplateFormProps {
  handleSubmitData: (data: CatalogTemplateFormProps) => void;
  defaultValues?: CatalogTemplateFormProps;
  activeTab: DocumentTabs;
  openProjectSlideOver: () => void;
  openMaterialsSlideOver: () => void;
  openCTDSectionsSlideOver: () => void;
  setFormData: Dispatch<SetStateAction<CatalogTemplateFormProps>>;
}

const SCHEMAS: Record<DocumentTabs, AnyZodObject> = {
  Target: targetSchema,
  'In-Progress': inProgressSchema,
  'Pre-Authored': automatedSchema,
  Automated: automatedSchema,
  Completed: automatedSchema,
  Source: sourceSchema,
};

const EditCatalogTemplateForm = forwardRef<unknown, EditCatalogTemplateFormProps>(
  (
    {
      handleSubmitData,
      defaultValues = CatalogTemplateFormValues,
      activeTab,
      openProjectSlideOver,
      openMaterialsSlideOver,
      openCTDSectionsSlideOver,
      setFormData,
    },
    ref,
  ) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm<CatalogTemplateFormProps>({
      resolver: zodResolver(SCHEMAS[activeTab]),
      mode: 'onChange',
      defaultValues: defaultValues,
    });

    const onSubmit: SubmitHandler<CatalogTemplateFormProps> = (data) => {
      handleSubmitData(data);
    };

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onSubmit),
    }));

    useEffect(() => {
      for (const key in defaultValues) {
        setValue(
          key as keyof CatalogTemplateFormProps,
          defaultValues[key as keyof CatalogTemplateFormProps],
        );
      }
    }, [defaultValues, setValue]);

    const inputList = getInputList(activeTab, {
      projects: { onClick: openProjectSlideOver },
      materials: {
        onClick: openMaterialsSlideOver,
      },
      ctdSections: {
        onClick: openCTDSectionsSlideOver,
      },
    });

    return (
      <form className="flex flex-col gap-4 self-stretch">
        {inputList.map((input, index) => (
          <Controller
            key={index}
            name={input.name as keyof CatalogTemplateFormProps}
            control={control}
            render={({ field }) => {
              if (typeof field.value === 'number' || typeof field.value === 'string')
                return (
                  <div className="w-full">
                    <Input
                      {...field}
                      onChange={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          [field.name]: e.target.value,
                        }));
                      }}
                      label={input.label}
                      type={input.type}
                      disabled={input.disabled}
                      className="w-full"
                      value={field.value}
                      onClick={input.onClick}
                      placeholder={input?.placeholder || ''}
                      step={0.1}
                      customStyles={{ input: { height: '2.625rem' } }}
                      error={!!errors[input.name as keyof CatalogTemplateFormProps]?.message}
                      helpText={
                        <InputError
                          errors={errors}
                          field={input.name as keyof CatalogTemplateFormProps}
                        />
                      }
                    />
                  </div>
                );
              else
                return (
                  <div className="w-full">
                    <TagsField
                      {...field}
                      value={field.value}
                      label={input.label}
                      disabled={input.disabled}
                      onClick={input.onClick}
                      error={!!errors[input.name as keyof CatalogTemplateFormProps]?.message}
                      helpText={<InputError errors={errors} field={input.name} />}
                    />
                  </div>
                );
            }}
          />
        ))}
      </form>
    );
  },
);

EditCatalogTemplateForm.displayName = 'EditCatalogTemplateForm';

export default EditCatalogTemplateForm;

import { getCellElement } from './generalizationHelpers';
import { mappingMeta } from '../types';
import { Document as TableDocument, Packer, Paragraph, Table, TableCell, TableRow } from 'docx';

export const getMappingNotes = (rejectionNotes = [], suggestionId: number) =>
  rejectionNotes.find((note: any) => note.mappingId === suggestionId) || {};

export const getTableTitle = (node: Element | null): string | undefined => {
  const table = node?.closest('table');
  if (!table) {
    return undefined;
  }
  const findTitle = (element: HTMLElement | null): string | undefined => {
    return element?.innerText?.includes('Table') ? element.innerText : undefined;
  };
  let currentElement: HTMLElement | null = table;
  while (currentElement && currentElement !== document.body) {
    const title = findTitle(currentElement.previousElementSibling as HTMLElement);
    if (title) {
      return title;
    }
    currentElement = currentElement.parentElement;
  }
  return undefined;
};

export const findTableNumberByTdNodeId = (tdNodeId: string, doc: Document): number => {
  const fileElement = doc.querySelector(`[data-nodeId='${tdNodeId}']`);
  const tableTitle = getTableTitle(fileElement);
  const regex = /^Table\s+(\d+):/;
  if (tableTitle) {
    const match = tableTitle.match(regex);
    if (match && match[1]) {
      return parseInt(match[1]);
    }
  }
  return 0;
};

export const constructSuggestionLocation = (
  doc: Document,
  nodeId: string,
): { tableNumber: number; tableColumn: number; tableRowNumber: number } => {
  const fileElement = doc.querySelector(`[data-nodeId='${nodeId}']`);
  let targetCell: HTMLTableCellElement | null = null;
  if (fileElement) targetCell = getCellElement(fileElement, doc.body);
  const tableNumber = findTableNumberByTdNodeId(nodeId, doc);

  const tableRow: HTMLElement | null = targetCell ? targetCell.parentElement : null;
  const tableColumn: number = targetCell ? targetCell.cellIndex + 1 : 0;
  let tableRowNumber = 0;

  if (tableRow && (tableRow as HTMLTableRowElement)?.rowIndex >= 0) {
    tableRowNumber = (tableRow as HTMLTableRowElement).rowIndex + 1;
  }

  const data = {
    tableNumber,
    tableColumn,
    tableRowNumber,
  };

  return data;
};

export const downloadHTML = (
  mappingMeta: mappingMeta[],
  mappingSessionTitle: string,
  sessionId: number,
  firstName: string,
  lastName: string,
) => {
  const rows = [
    new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph('Receiving Document Type')],
          columnSpan: 1,
        }),
        new TableCell({
          children: [new Paragraph('')],
          columnSpan: 5,
        }),
      ],
    }),
    new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph('Document Title')],
          columnSpan: 1,
        }),
        new TableCell({
          children: [new Paragraph(`${mappingSessionTitle}`)],
          columnSpan: 5,
        }),
      ],
    }),
    new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph('Veeva record number and version')],
          columnSpan: 1,
        }),
        new TableCell({
          children: [new Paragraph('')],
          columnSpan: 5,
        }),
      ],
    }),
    new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph(`Source Document
          (Veeva document
          number and version)`),
          ],
          columnSpan: 1,
        }),
        new TableCell({
          children: [
            new Paragraph(`Source Document Data
          Elements Used (e.g. table,
          figure, section, pages)`),
          ],
          columnSpan: 2,
        }),
        new TableCell({
          children: [
            new Paragraph(`Receiving
          Document Where
          Source Used
          (e.g. table,
          figure, section,
          pages)`),
          ],
          columnSpan: 1,
        }),
        new TableCell({
          children: [
            new Paragraph(`Data Verification Findings:
          Description of any discrepancies and justification of
          acceptability
          (e.g. “all values comply”, “significant figures aligned to
          product specification”, “narrative confirmed by SME”)`),
          ],
          columnSpan: 2,
        }),
      ],
    }),
  ];

  mappingMeta.map((file) => {
    rows.push(
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph(file.originalName)],
            columnSpan: 1,
          }),
          new TableCell({
            children: [new Paragraph(file.sourceLocation)],
            columnSpan: 2,
          }),
          new TableCell({
            children: [new Paragraph(file.targetLocation)],
            columnSpan: 1,
          }),
          new TableCell({
            children: [
              new Paragraph(`${file.acceptedSuggestionsCount} values verified using ModernAuthor`),
            ],
            columnSpan: 2,
          }),
        ],
      }),
    );
  });

  rows.push(
    new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph('DV Performed By/Date:')],
          columnSpan: 1,
        }),
        new TableCell({
          children: [new Paragraph('Name:')],
          columnSpan: 1,
        }),
        new TableCell({
          children: [new Paragraph(`${firstName} ${lastName}`)],
          columnSpan: 2,
        }),
        new TableCell({
          children: [new Paragraph('Date:')],
          columnSpan: 1,
        }),
        new TableCell({
          children: [new Paragraph(`${new Date().toLocaleDateString()}`)],
          columnSpan: 1,
        }),
      ],
    }),
  );

  const table = new Table({
    rows: rows,
    width: {
      size: 100,
    },
  });

  const doc = new TableDocument({
    sections: [
      {
        children: [table],
      },
    ],
  });
  Packer.toBlob(doc).then((blob) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `DV Form Records - ${`${mappingSessionTitle}(${sessionId}) - DV Records.docx`}`;
    a.click();
    URL.revokeObjectURL(url);
  });
};

import { FilterOptions } from '@gloabal-regulatory-writing-consulting/gxt-components/dist/components/slideOverFilters/SlideOverFilters';
import { capitalizeFirstLetter } from '../../../../../helpers/utils';
import { GroupedProcessids, Processids } from '../../../../../services/api/types';
import { MenuItemType } from '../../../../../components/PaginatedTable/PaginatedTable.types';
import { selectedProjectType } from '../../../types';

export const mapProcessidsToOptions = (
  groupedProcessids: {
    groupedProcessids: { materialId: number; processIds: { id: number; name: string }[] }[];
  },
  materials: { id: number; name: string }[],
  selectedIds: number[],
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>,
): FilterOptions[] => {
  const processIdsArray = groupedProcessids?.groupedProcessids;

  if (!Array.isArray(processIdsArray)) {
    return [];
  }

  return processIdsArray.map((groupedProcessId) => {
    const material = materials.find((g) => g.id === groupedProcessId.materialId);
    const header = material ? material.name : `Material ${groupedProcessId.materialId}`;

    const checkboxOptions = groupedProcessId.processIds.map((processId) => {
      const isChecked = selectedIds.includes(processId.id);
      return {
        label: processId.name,
        value: processId.id,
        name: header,
        checked: isChecked,
        onChange: () => {
          setSelectedIds((prevSelectedIds) => {
            const isCurrentlyChecked = prevSelectedIds.includes(processId.id);
            const newSelectedIds = isCurrentlyChecked
              ? prevSelectedIds.filter((id) => id !== processId.id)
              : [...prevSelectedIds, processId.id];
            return newSelectedIds;
          });
        },
      };
    });

    return {
      header,
      isAccordionOpen: true,
      isSearchAble: true,
      onChangeCallback: () => {},
      checkboxOptions,
    };
  });
};

export const mapSelectedGroupsToOptions = (
  selectedGroups: number[],
  materials: { id: number; name: string }[],
): { value: string; label: string }[] => {
  return selectedGroups.reduce(
    (acc, id) => {
      const material = materials.find((material) => material.id === id);
      if (material) {
        acc.push({ value: material.name, label: capitalizeFirstLetter(material.name) });
      }
      return acc;
    },
    [] as { value: string; label: string }[],
  );
};

export const mapSelectedProcessIdsToOptions = (
  processIds: { groupedProcessids: GroupedProcessids[] },
  selectedProcessIds: number[],
): { value: string; label: string }[] => {
  const groupedProcessids = processIds?.groupedProcessids;

  if (groupedProcessids && Array.isArray(groupedProcessids)) {
    const allProcessids = groupedProcessids.flatMap((groupedProcessId) =>
      groupedProcessId.processIds.map((processId: Processids) => ({
        id: processId.id,
        name: processId.name,
      })),
    );
    return selectedProcessIds.reduce(
      (acc, id) => {
        const processId = allProcessids.find((processId) => processId.id === id);
        if (processId) {
          acc.push({
            value: processId.name,
            label: capitalizeFirstLetter(processId.name),
          });
        }
        return acc;
      },
      [] as { value: string; label: string }[],
    );
  }
  return [] as { value: string; label: string }[];
};

export const getProjectMenuItems = (
  navigate: (path: string, options: { state: any }) => void,
  handleDeleteClick: (row: selectedProjectType) => void,
): MenuItemType<any>[] => [
  {
    title: 'Edit',
    onClick: (id, row) => {
      navigate('/createProject', {
        state: { action: 'update', id, projectName: row.getValue('name') },
      });
    },
  },
  {
    title: 'Delete',
    onClick: (id, row) => {
      handleDeleteClick({ id: row.getValue('id'), name: row.getValue('name') });
    },
    className: 'text-negative-50 hover:text-negative-100',
  },
];

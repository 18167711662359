// React-related imports
import { useEffect, useState } from 'react';

// Types
import {
  PaginationFilteredParams,
  PaginationParams,
  SearchParams,
  SortOrderType,
} from '../../services/api/types';

export const usePaginatedFilters = <F, S>({
  filters: defaultFilters,
  search: defaultSearch = {
    query: '',
    columns: [],
  },
  pagination: defaultPagination = {
    page: 1,
    perPage: 10,
  },
  sort: defaultSort,
}: PaginationFilteredParams<F, S>) => {
  const [search, setSearch] = useState(defaultSearch);
  const [pagination, setPagination] = useState(defaultPagination);
  const [filters, setFilters] = useState(defaultFilters);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [sort, setSort] = useState(defaultSort);

  const resetPagination = () => {
    setPagination({ ...defaultPagination });
  };

  const setSearchByKey = (key: keyof SearchParams, value: string) => {
    if (key === 'query') {
      resetPagination();
    }
    setSearch((prev) => ({ ...prev, [key]: value }));
  };

  const setPaginationByKey = (key: keyof PaginationParams, value: string | number) => {
    setPagination((prev) => ({ ...prev, [key]: value }));
  };

  const setFiltersByKey = (key: keyof F, value: string) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handlePageChange = (page: number) => {
    setPaginationByKey('page', page);
  };

  const handlePerPageChange = (perPage: number) => {
    setPaginationByKey('perPage', perPage);
  };

  const handleColumnSort = (sortType: SortOrderType, columnSort: keyof S) => {
    setSort({
      column: columnSort,
      type: sortType,
    });
  };

  const paginatedFilteredData = {
    search,
    pagination,
    filters,
    sort,
  };

  useEffect(() => {
    setSearch(defaultSearch);
  }, [defaultSearch]);

  return {
    search,
    sort,
    pagination,
    setPagination,
    filters,
    setFilters,
    setSearchByKey,
    setPaginationByKey,
    setFiltersByKey,
    appliedFiltersCount,
    setAppliedFiltersCount,
    paginatedFilteredData,
    handlePageChange,
    handlePerPageChange,
    handleColumnSort,
  };
};

/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createColumnHelper } from '@tanstack/react-table';
import { getDate } from '../../../../../helpers/catalogHelpers';
import { TableHeader } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { ProjectSettings } from '../../../../../types';
import { ProjectSortParams, SortOrderType } from '../../../../../services/api/types';
import { getUserFullName } from '../../../../../helpers/utils';
import { ProjectSettingsTabs } from '../../../types';

export const generateColumns = (
  handleColumnSort: (sort: SortOrderType, column: keyof ProjectSortParams) => void,
  sortedColumn: { column: string; order: SortOrderType },
) => {
  const projectSettingsColumnHelper = createColumnHelper<ProjectSettings>();

  const createTableHeader = (
    title: string,
    accessor = '',
    activeColumn = { column: '', order: 'ASC' as SortOrderType },
  ) => {
    return () => (
      <TableHeader
        ColumnName={accessor}
        Title={title}
        handleColumnSort={handleColumnSort as (sort: SortOrderType, column: string) => void}
        activeColumn={activeColumn}
      />
    );
  };

  const columns: Record<ProjectSettingsTabs, any[]> = {
    [ProjectSettingsTabs.PROJECT_SETTINGS]: [
      projectSettingsColumnHelper.accessor('name', {
        header: createTableHeader('Project', 'name', sortedColumn),
        cell: (info) => info.getValue(),
        size: 1018,
      }),
      projectSettingsColumnHelper.accessor('createdAt', {
        header: createTableHeader('Created On', 'createdAt', sortedColumn),
        cell: (info) => {
          const createdAt = info.row.original.createdAt;
          return createdAt && getDate(createdAt);
        },
        size: 126,
      }),
      projectSettingsColumnHelper.accessor('createdBy', {
        header: createTableHeader('Created By', 'createdBy', sortedColumn),
        cell: (info) => getUserFullName(info.row.original.createdBy),
        size: 160,
      }),
    ],
  };

  return columns[ProjectSettingsTabs.PROJECT_SETTINGS];
};

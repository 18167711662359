import { useContext } from 'react';

import { Button, Dropdown, Header } from '@gloabal-regulatory-writing-consulting/gxt-components';

import { DataVerificationHeaderProps } from './types';

import { GeneralizationContext } from '../../../../contexts/GeneralizationContext';
import { useMappingSessionAPI } from '../../../../services/api/mappingSession/useMappingSessionAPI';
import { getDropdownOptions } from '../../../../helpers/utils';
import { StatusType } from '../../../../services/api/types';

const DataVerificationHeader = ({
  handleExportDocument,
  handleClose,
  handleViewComments,
  handleCommentOnClick,
  isViewCommentsDisabled = false,
  isCommentDisabled = false,
  isExportDisabled = false,
}: DataVerificationHeaderProps) => {
  const { sessionId } = useContext(GeneralizationContext);

  const { updateMappingSessionStatus } = useMappingSessionAPI();
  const { mutateAsync: updateSessionStatus, isPending } = updateMappingSessionStatus;

  const statusUpdateHandler = (status: string) => {
    if (sessionId) {
      updateSessionStatus({ status, id: sessionId });
    } else {
      console.error('Unable to find session ID');
    }
  };

  return (
    <Header>
      <Header.Heading className="!text-primary-400">Data Verification</Header.Heading>
      <Header.Actions>
        <Dropdown
          customStyles={{
            placeholder: { color: 'var(--neutral-400)' },
          }}
          dropdownIcon
          onSelect={(option) => {
            handleExportDocument(option.value);
          }}
          groupedOptions={[
            {
              header: 'Export Document',
              options: getDropdownOptions(['Annotated Document', 'Clean Document']),
            },
            {
              header: 'Export DV Records',
              options: getDropdownOptions([
                'Detailed Records',
                'Finalized Records',
                'DV Form Records',
              ]),
              disabled: isExportDisabled,
            },
            {
              header: '',
              options: getDropdownOptions(['Export Rejection Notes']),
            },
          ]}
          placeholder="Export"
          position="bottom"
          type="button"
          menuType="divided"
        />
        <Button variant="negative" onClick={handleClose} disabled={isPending}>
          Close
        </Button>
        <div className="h-10 border width-2 border-neutral-200" />
        <Button variant="secondary" onClick={handleViewComments} disabled={isViewCommentsDisabled}>
          View Comments
        </Button>
        <Button variant="secondary" onClick={handleCommentOnClick} disabled={isCommentDisabled}>
          Comment
        </Button>
        <div className="h-10 border width-2 border-neutral-200" />
        <Button
          variant="primary"
          onClick={() => statusUpdateHandler(StatusType.AUTHOR_REVIEW)}
          disabled={isPending}>
          Author Review
        </Button>
      </Header.Actions>
    </Header>
  );
};

export default DataVerificationHeader;

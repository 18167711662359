import { toast } from 'react-toastify';
import { DateFormatOptions } from '../types';
export const notifySuccess = (text: string) => toast.success(text, { theme: 'light' });
export const notifyError = (text: string) => toast.error(text, { theme: 'light' });

const MAX_RETRY_COUNT = 3;

export const formatDate = (
  date: Date,
  options: DateFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
): string => {
  const formatter = new Intl.DateTimeFormat('en-US', options);

  return formatter.format(date);
};

export const removeNullFalseEmptyStringKeys = (obj: any) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));
};

export const generateUsernameFromName = (name: string) => {
  const [firstName, lastName] = name.toLowerCase().split(' ');
  const username = `${firstName[0]}${lastName}`;
  return username;
};

export const isPasswordValid = (password: string) => {
  // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
  return passwordRegex.test(password);
};

export const capitalizeFirstLetter = (str: string) => {
  if (!str?.trim()) return '';
  return (str.charAt(0).toUpperCase() + str.slice(1)).trim();
};

export const retry = async <T>(cb: () => Promise<T>, retries = MAX_RETRY_COUNT) => {
  let error: any;
  for (let i = 0; i < retries; i++) {
    try {
      return await cb();
    } catch (err) {
      console.error(`retrying ${i}`);
      error = err;
    }
  }
  if (error) console.error(error);
  return undefined;
};

export const containsListNumbering = (str: string) => {
  const regex = /^\s*(\d+(\.\d+)+)\s*$/;
  const matches = str.trim().match(regex);
  return matches !== null && matches[1] === str.trim();
};

export const normalizeString = (str: string) => {
  return str.replace(/\s+/g, ' ');
};

export const convertNumbToDecimal = (num: number, decimalPlaces = 2) => {
  if (typeof num !== 'number') num = parseFloat(num);
  if (isNaN(num)) return num;
  const isDecimal = num % 1 !== 0;
  if (isDecimal) return num;
  return num.toFixed(decimalPlaces);
};

export const getDropdownOptions = <T>(options: T[]) => options.map((option) => ({ value: option }));

export const getUserFullName = (createdBy: { firstName: string; lastName: string } | null) => {
  if (createdBy) {
    return `${createdBy.firstName} ${createdBy.lastName}`;
  }
  return '';
};

export const handleInfiniteScroll = (
  event: React.UIEvent<HTMLElement>,
  hasNextPage: boolean,
  isFetchingNextPage: boolean,
  fetchNextPage: any,
) => {
  const { scrollTop, scrollHeight, clientHeight } = event.target as HTMLElement;
  if (scrollHeight - scrollTop <= clientHeight && hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }
};

import { CatalogTemplateFormProps } from '../EditCatalogTemplate.types';

export const CatalogTemplateFormValues: CatalogTemplateFormProps = {
  id: undefined,
  templateName: '',
  version: undefined,
  materials: [],
  ctdSections: [],
  project: [],
};

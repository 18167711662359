import { Button, SlideOver } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { FC, useMemo, useRef, useState } from 'react';
import { CheckboxSelectionSlideOver } from '../../../../components/CheckboxSelectionSlideOver';
import useModal from '../../../../hooks/useModal';
import { getProjects, useMappingSessionAPI } from '../../../../services/api';
import { ItemType } from '../../../../services/api/types';
import { getAllCatalogItemsWithPagination } from '../../../settings/components/utils/apiHelpers';
import CopyDocumentForm from './CopyDocumentForm';
import { CopyDocumentDefaultValues, CopyDocumentSlideOverProps } from './types';
import { perPageFilterOptions } from '../../../../constants/catalog';
import { handleInfiniteScroll } from '../../../../helpers/utils';

const CopyDocumentSlideOver: FC<CopyDocumentSlideOverProps> = ({
  handleClose,
  isOpen,
  sessionData,
  onStartAuthoring,
}) => {
  const [formData, setFormData] = useState<CopyDocumentDefaultValues>({
    title: '',
    project: sessionData.project
      ? [{ label: sessionData.project.name, value: sessionData.project.id.toString() }]
      : [],
    ctdSections: (sessionData?.catalogItems?.CTDSection || []).map((ctdSection) => ({
      label: ctdSection.name,
      value: ctdSection.id.toString(),
    })),
    version: sessionData.version,
  });

  const formRef = useRef<{ submitForm: () => void }>(null);
  const projectSlideOver = useModal();
  const ctdSectionsSlideOver = useModal();

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const { copySession } = useMappingSessionAPI();

  const { mutateAsync: sendCopyRequest, isPending } = copySession;

  const submitData = async () => {
    const data = {
      title: formData.title,
      projectId: +formData.project[0].value,
      ctdSectionIds: formData.ctdSections.map((ctdSection) => +ctdSection.value),
      id: sessionData.id,
      version: formData.version,
    };
    const response = await sendCopyRequest(data);
    onStartAuthoring(response.data.copiedSession.id.toString());
  };

  const updateData = (
    key: keyof CopyDocumentDefaultValues,
    value: CopyDocumentDefaultValues[keyof CopyDocumentDefaultValues],
  ) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const { data: projectResponse } = useQuery({
    queryKey: ['catalog-edit-projects'],
    queryFn: () => getProjects({}),
  });

  const {
    data: ctdSectionsResponse,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [ItemType.CTDSection],
    queryFn: ({ pageParam = 1 }) =>
      getAllCatalogItemsWithPagination(ItemType.CTDSection, {
        page: pageParam,
        perPage: perPageFilterOptions,
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.prevOffset * perPageFilterOptions > lastPage.total) {
        return undefined;
      }
      return lastPage?.prevOffset + 1;
    },
    initialPageParam: 1,
  });

  const projectsData = useMemo(
    () =>
      (projectResponse?.data.data || []).map(({ id, name }) => ({
        value: id.toString(),
        label: name,
      })),
    [projectResponse],
  );

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    handleInfiniteScroll(event, hasNextPage, isFetchingNextPage, fetchNextPage);
  };

  const ctdSectionsData = useMemo(
    () =>
      ctdSectionsResponse?.pages?.flatMap(
        (page) =>
          page?.items.map(({ id, name }) => ({
            value: id?.toString(),
            label: name,
          })),
      ) || [],
    [ctdSectionsResponse],
  );

  return (
    <SlideOver isOpen={isOpen} onClose={handleClose} mountElementId="styled-wrapper" width="28rem">
      <SlideOver.Header className="flex p-6 flex-col items-start gap-2 self-stretch bg-primary-50 text-system-50">
        New Document Information
      </SlideOver.Header>
      <CopyDocumentForm
        defaultValues={formData}
        submitData={submitData}
        setDefaultData={updateData}
        openProjectSlideOver={projectSlideOver.openModal}
        openCTDSectionSlideOver={ctdSectionsSlideOver.openModal}
        ref={formRef}
      />
      {projectSlideOver.show && (
        <CheckboxSelectionSlideOver
          isOpen={projectSlideOver.show}
          handleClose={projectSlideOver.closeModal}
          onSave={(value) => setFormData({ ...formData, project: value })}
          options={projectsData}
          selectedOptions={formData.project}
          displayBackButton
          title="Projects"
          multiple={false}
        />
      )}
      {ctdSectionsSlideOver.show && (
        <CheckboxSelectionSlideOver
          isOpen={ctdSectionsSlideOver.show}
          handleClose={ctdSectionsSlideOver.closeModal}
          onSave={(value) => setFormData({ ...formData, ctdSections: value })}
          options={ctdSectionsData}
          selectedOptions={formData.ctdSections}
          displayBackButton
          title="CTD Sections"
          handleScroll={handleScroll}
          fetchingNextPage={isFetchingNextPage}
        />
      )}
      <SlideOver.Footer className="flex flex-col items-center self-stretch">
        <div className="flex gap-4 justify-end items-center flex-grow flex-shrink flex-basis-0 w-full">
          <Button variant="primary" disabled={isPending} onClick={handleSubmit}>
            Start Authoring
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </SlideOver.Footer>
    </SlideOver>
  );
};

export default CopyDocumentSlideOver;

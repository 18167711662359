import _ from 'lodash';
import { AIResponse, DocumentChange, EditorRef, GeneralizationSuggestionMap } from '../types';
import { getElement, getCellElement } from './generalizationHelpers';
import { RefObject } from 'react';
import { mappingByType } from '../constants';

export const excludeStyleAttribute = (html: string): string => {
  return html.replace(/(<[^>]+) style=".*?"/gi, '$1');
};

export const removeSingleBrChild = (node: Element): Element => {
  if (node.children.length === 1 && node.children[0].tagName.toLowerCase() === 'br') {
    node.removeChild(node.children[0]);
  }

  return node;
};

const getUpdatedInnerHtml = (node: Element): string => {
  const innerHTML = excludeStyleAttribute(node.innerHTML);
  return _.unescape(innerHTML);
};

export const compareDocuments = (
  originalDocument: Document,
  updatedDocument: Document,
): Record<string, DocumentChange> => {
  const changes: Record<string, DocumentChange> = {};

  if (originalDocument && updatedDocument) {
    const updatedNodes = updatedDocument.querySelectorAll<Element>('[data-nodeId]');

    updatedNodes.forEach((updatedNode) => {
      const nodeId = updatedNode.getAttribute('data-nodeId') || '';
      const updatedInnerHtml = getUpdatedInnerHtml(updatedNode);

      const originalNode = originalDocument.querySelector<Element>(`[data-nodeId='${nodeId}']`);

      handleNodeChanges(nodeId, originalNode, updatedNode, updatedInnerHtml, changes);
    });
  }

  return changes;
};

const handleNodeChanges = (
  nodeId: string,
  originalNode: Element | null,
  updatedNode: Element,
  updatedInnerHtml: string,
  changes: Record<string, DocumentChange>,
): void => {
  const isEmptyNode = removeSingleBrChild(updatedNode).children.length === 0;

  if (isEmptyNode && originalNode) {
    handleEmptyNodeWithOriginal(nodeId, originalNode, updatedInnerHtml, changes);
  } else {
    handleNonEmptyNodeOrMissingNode(nodeId, originalNode, updatedNode, updatedInnerHtml, changes);
  }
};

const handleEmptyNodeWithOriginal = (
  nodeId: string,
  originalNode: Element,
  updatedInnerHtml: string,
  changes: Record<string, DocumentChange>,
): void => {
  const originalInnerHtml = getUpdatedInnerHtml(originalNode);

  if (originalInnerHtml !== updatedInnerHtml) {
    changes[nodeId] = {
      old: _.unescape(originalNode.innerHTML),
      updated: updatedInnerHtml,
    };
  }
};

const handleNonEmptyNodeOrMissingNode = (
  nodeId: string,
  originalNode: Element | null,
  updatedNode: Element,
  updatedInnerHtml: string,
  changes: Record<string, DocumentChange>,
): void => {
  if (!originalNode) {
    const updatedNodeTextContent = getUpdatedInnerHtml(updatedNode);
    if (updatedNodeTextContent) {
      changes[nodeId] = { old: '', updated: updatedInnerHtml };
    }
  } else {
    handleDifferentTextContent(nodeId, originalNode, updatedNode, updatedInnerHtml, changes);
  }
};

const handleDifferentTextContent = (
  nodeId: string,
  originalNode: Element,
  updatedNode: Element,
  updatedInnerHtml: string,
  changes: Record<string, DocumentChange>,
): void => {
  const oldNodeTextContent = getUpdatedInnerHtml(originalNode);
  const updatedNodeTextContent = getUpdatedInnerHtml(updatedNode);

  if (oldNodeTextContent !== updatedNodeTextContent) {
    changes[nodeId] = {
      old: oldNodeTextContent,
      updated: updatedNodeTextContent,
    };
  }
};

export const sanitizeHtml = (html: string | null): string => {
  // Replace occurrences of ' style=""' with a single space.
  const step1 = html?.replace(/ style=""|&nbsp;|\u00A0/g, ' ') || '';

  // Remove background color from the style attribute if it exists.
  const step2 = step1.replace(/ style=".*?background-color:.*?"/g, '');

  // Remove data-highlighted attribute if it exists.
  const step3 = step2.replace(/ data-highlighted=".*?"/g, '');

  // If the style attribute is now empty, replace it with a single space.
  const sanitizedHtml = step3.replace(/ style=""/g, ' ');

  return sanitizedHtml;
};

const checkStringEquality = (string1: string, string2: string): boolean => {
  const unescapedString1 = _.unescape(string1);
  const unescapedString2 = _.unescape(string2);

  const div1 = document.createElement('div');
  const div2 = document.createElement('div');

  div1.innerHTML = unescapedString1;
  div2.innerHTML = unescapedString2;

  return _.isEqual(div1.innerHTML, div2.innerHTML);
};

export const getUpdatedCells = (
  documentChanges: Record<string, { old: string; updated: string }>,
  sessionId: number,
  editor1Ref: RefObject<EditorRef>,
  acceptedSuggestionsHash: GeneralizationSuggestionMap,
  updatedAiResponse: AIResponse,
) => {
  const updatedCells: any[] = [];

  Object.values(acceptedSuggestionsHash).forEach((acceptedSuggestion) => {
    const element = getElement(editor1Ref, acceptedSuggestion.targetNodeId);
    if (updatedAiResponse[acceptedSuggestion.targetNodeId]) {
      if (!element) return;
      const node = getCellElement(element, editor1Ref?.current?.getBody() as Node) || element;
      const htmlNode =
        node.tagName === 'IMG'
          ? (node as HTMLImageElement).getAttribute('src') || ''
          : sanitizeHtml(node.innerHTML);
      const suggestionValue = sanitizeHtml(acceptedSuggestion.targetValue);
      if (!checkStringEquality(htmlNode, suggestionValue)) {
        updatedCells.push({
          ...acceptedSuggestion,
          sessionId: sessionId,
          documentId: acceptedSuggestion.sourceFileId,
          previousSuggestionId: acceptedSuggestion.id,
          targetValue: htmlNode || '',
        });
      }
    }
  });

  for (const [nodeId, change] of Object.entries(documentChanges)) {
    const previousSuggestion = acceptedSuggestionsHash[nodeId];

    const element = getElement(editor1Ref, nodeId);
    const cellElement = element && getCellElement(element, editor1Ref.current?.getBody() as Node);
    if (
      (!updatedAiResponse[nodeId] || !previousSuggestion) &&
      !updatedCells.some((cell) => cell.targetNodeId === cellElement?.getAttribute('data-nodeid'))
    ) {
      updatedCells.push({
        targetNodeId: nodeId,
        sessionId: sessionId,
        previousSuggestionId: previousSuggestion?.id,
        targetValue: change.updated,
        mapperType: mappingByType.author,
        order: previousSuggestion?.order,
      });
    }
  }

  return updatedCells;
};

// React-related imports
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';

// React Hook Form imports
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

// Component imports
import { Input, SlideOverFilters } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { InputError } from '../../../../components/inputError';

// Type and utility imports
import {
  ProjectFormProps,
  CreateProjectFormValues,
  UpdateProjectFormValues,
} from './CreateNewProjectForm.types';
import { inputList } from './utils';

// Services and API's imports
import { ItemType } from '../../../../services/api/types';
import { getProcessidsCatalogItems } from '../../../../services/api';

// Hook imports
import useModal from '../../../../hooks/useModal';
import { getAllCatalogItems } from '../../../settings/components/utils/apiHelpers';
import { useQuery } from '@tanstack/react-query';
import { RolesSlideOver } from '../../../createUser/components/RolesSlideOver';
import { mapProcessidsToOptions } from './utils/helper';

const CreateProjectForm = forwardRef(
  ({ defaultValues, action, createHandler, updateHandler }: ProjectFormProps, ref) => {
    const [selectedMaterials, setSelectedMaterials] = useState<number[]>([]);
    const [selectedProcessIds, setSelectedProcessIds] = useState<number[]>([]);

    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<CreateProjectFormValues | UpdateProjectFormValues>({
      defaultValues,
      mode: 'onChange',
    });

    const formSubmitHandler: SubmitHandler<CreateProjectFormValues | UpdateProjectFormValues> = (
      data: CreateProjectFormValues | UpdateProjectFormValues,
    ) => {
      if (action === 'create') {
        createHandler(data);
      } else {
        updateHandler(data as UpdateProjectFormValues);
      }
    };

    const [materialsSlideOver, processIdsSlideOver] = [useModal(), useModal()];

    const { data: materials, isLoading: materialsLoading } = useQuery({
      queryKey: [ItemType.Material + 'project'],
      queryFn: () => getAllCatalogItems(ItemType.Material),
      placeholderData: (previousData) => previousData,
    });

    const { data: processIds } = useQuery({
      queryKey: [ItemType.ProcessID + 'project', selectedMaterials],
      queryFn: () => getProcessidsCatalogItems(selectedMaterials),
      placeholderData: (previousData) => previousData,
      enabled: selectedMaterials.length > 0,
    });

    const filtersOptionsData = useMemo(
      () =>
        mapProcessidsToOptions(
          processIds?.data as any,
          materials?.length ? materials : [],
          selectedProcessIds,
          setSelectedProcessIds,
        ),
      [processIds, materials, selectedProcessIds],
    );

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(formSubmitHandler),
    }));

    return (
      <>
        <form className="flex flex-col items-start gap-6 flex-1 flex-shrink-0 basis-0">
          {inputList.map((input) => (
            <Controller
              key={input.name}
              {...input}
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id={input.name}
                    data-testId={input.name}
                    label={input.label}
                    placeholder={input.placeholder}
                    isFilled={!!field.value}
                    value={field.value?.toString()}
                    customStyles={{
                      container: { width: '23.75rem' },
                    }}
                    error={!!errors[input.name]?.message}
                    helpText={<InputError errors={errors} field={input.name} />}
                  />
                </>
              )}
            />
          ))}
        </form>
        {materialsSlideOver.show && !materialsLoading && (
          <RolesSlideOver
            isOpen={materialsSlideOver.show}
            heading="Assign Materias"
            onClose={materialsSlideOver.closeModal}
            handleSave={(selectedmaterials) => {
              setSelectedMaterials(selectedmaterials);
              materialsSlideOver.closeModal();
            }}
            onCloseSlideOver={materialsSlideOver.closeModal}
            selectedRoles={selectedMaterials}
            allRoles={Object.values(materials ?? {})}
          />
        )}
        {processIdsSlideOver.show && (
          <SlideOverFilters
            isOpen={processIdsSlideOver.show}
            onCloseHandler={processIdsSlideOver.closeModal}
            onApplyHandler={() => processIdsSlideOver.closeModal()}
            onResetHandler={() => {}}
            filtersOptions={filtersOptionsData}
            applyButtonLabel="Add"
            closeButtonLabel="Close"
            resetButtonLabel="Clear Selection"
            title="Assign Processids"
          />
        )}
      </>
    );
  },
);

CreateProjectForm.displayName = 'CreateProjectForm';

export default CreateProjectForm;

import { DocumentTabs } from '../../../../../types';

export const getInputList = (
  activeTab: DocumentTabs,
  options?: Record<string, { onClick?: () => void; disabled?: boolean }>,
): {
  label: string;
  name: string;
  type: string;
  disabled: boolean;
  placeholder?: string;
  onClick?: () => void;
}[] => {
  switch (activeTab) {
    case DocumentTabs.AUTOMATED:
      return [
        {
          label: 'Document Name',
          name: 'templateName',
          type: 'string',
          disabled: false,
          ...(options || {})['templateName'],
        },
        {
          label: 'CTD Section',
          name: 'ctdSections',
          type: 'tags',
          disabled: false,
          ...(options || {})['ctdSections'],
        },
        {
          label: 'Version',
          name: 'version',
          type: 'number',
          disabled: false,
          placeholder: '1.00',
          ...(options || {})['version'],
        },
      ];
    case DocumentTabs.IN_PROGRESS:
      return [
        {
          label: 'Document Name',
          name: 'title',
          type: 'string',
          disabled: false,
          ...(options || {})['title'],
        },
        {
          label: 'Project',
          name: 'project',
          type: 'tags',
          disabled: false,
          ...(options || {})['projects'],
        },
        {
          label: 'CTD Section',
          name: 'ctdSections',
          type: 'tags',
          disabled: false,
          ...(options || {})['ctdSections'],
        },
      ];
    case DocumentTabs.TARGET:
      return [
        {
          label: 'CTD Section',
          name: 'ctdSections',
          type: 'tags',
          disabled: false,
          ...(options || {})['ctdSections'],
        },
      ];
    default:
      return [
        {
          label: 'Material',
          name: 'materials',
          type: 'tags',
          disabled: false,
          ...(options || {})['materials'],
        },
      ];
  }
};
